import { render, staticRenderFns } from "./ApruveEmailView.vue?vue&type=template&id=142e4583&scoped=true"
import script from "./ApruveEmailView.vue?vue&type=script&lang=js"
export * from "./ApruveEmailView.vue?vue&type=script&lang=js"
import style0 from "./ApruveEmailView.vue?vue&type=style&index=0&id=142e4583&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142e4583",
  null
  
)

export default component.exports