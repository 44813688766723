<template>
  <div class="alertActiveAccount">
    <p>
      Для завершения регистрации перейдите по&nbsp;ссылке, отправленной
      на&nbsp;email
      {{ email }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    email: { type: String, required: false },
  },
  name: 'AlertActiveAccount'
}
</script>
<style lang="scss" scoped>
.alertActiveAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 12px;
  width: 100%;
  min-height: 60px;
  background: #fcd3d3;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.015em;
  
  p {
    color: #ef2322;
  }
}
</style>
